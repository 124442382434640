<script setup>
import Dropdown from '@/components/ui/Dropdown.vue'

import { ref, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import LanguageConverter from '@/util/LanguageConverter'

const enterThisPage = ref(true)
const currentPage = ref('')
const store = useStore()
const route = useRoute()
const router = useRouter()
const menuList = store.state.menuStore.menuList

const mapCommunityMenu = (item) => {
  return {
    value: item.path,
    text: item.name,
    textEn: item.nameEn
  }
}
const communityMenu = [...menuList.find((item) => item.path === '/community').children].map(mapCommunityMenu)
const menuAdd = [
  // { value: '/community/news', text: '대학별 뉴스', textEn: 'News' },
  // { value: '/community/gallery', text: '갤러리', textEn: 'Gallery' },
  { value: '/community/pjt', text: '프로젝트', textEn: 'Project' },
  { value: '/community/paper', text: '논문', textEn: 'Paper' },
  { value: '/community/patent', text: '지재권', textEn: 'Patent' },
  { value: '/community/handover', text: '기업협력', textEn: 'Tech Cooperation' }
]
const communityMenuList = [...communityMenu, ...menuAdd]

const initialOption = { value: '', text: LanguageConverter({ en: 'Community', kr: '커뮤니티' }) }

const selectCommunityMenu = (option) => {
  if (enterThisPage.value) {
    enterThisPage.value = false
    return
  }
  router.push(option.value)
}

const goHome = () => {
  router.push('/')
}

const findCurrentPage = () => {
  for (const menu of communityMenuList) {
    if (route.path.includes(menu.value)) return LanguageConverter({ en: menu.textEn, kr: menu.text })
  }
}

// router 변경 감지
watch(
  () => route.path,
  () => (currentPage.value = findCurrentPage())
)

onMounted(() => {
  currentPage.value = findCurrentPage()
})
</script>

<template>
  <section class="border head-section">
    <ul>
      <li class="border-right border-left cursor-pointer" @click="goHome">
        <span class="material-icons-outlined"> home </span>
      </li>
      <li class="wf__community-menu-dropdown border-right cursor-pointer">
        <Dropdown
          class="wf__dropdown"
          :options="communityMenuList"
          :disabled="false"
          :initialOption="initialOption"
          :initAfterSelect="true"
          :borderNone="true"
          :dropdownStyle="{ padding: '.5em 1em' }"
          name="zipcode"
          :maxItem="3"
          @selected="selectCommunityMenu"
        />
      </li>
      <li class="border-right" :style="{ 'font-size': 'var(--wf-text-16)' }">{{ currentPage }}</li>
    </ul>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.head-section {
  display: flex;
  justify-content: center;
  z-index: 99;
  font-size: 14px;
}

.head-section ul {
  display: flex;
  list-style: none;
  width: 100%;
  max-width: 1620px;
}

.head-section ul li {
  display: flex;
  align-items: center;
  padding: 0em 1em;
}
.wf__community-menu-dropdown {
  padding: 0 !important;
}
.wf__dropdown {
  text-align: left;
}
</style>
