//INFO: 화면설명
<script>
import MainBackground from '@/assets/images/004.jpg'
import ImageBackgroundVue from '@/components/ui/ImageBackground.vue'
import MenuNavigatorVue from '@/components/Community/MenuNavigator.vue'

import LanguageConverter from '@/util/LanguageConverter'

export default {
  name: '',
  components: {
    ImageBackgroundVue,
    MenuNavigatorVue
  },
  data() {
    return {
      src: MainBackground
    }
  },
  methods: {
    LanguageConverter
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {}
}
</script>

<template>
  <section class="head-section">
    <ImageBackgroundVue :src="src">
      <div>
        <h2 class="wf__subtitle">
          {{
            LanguageConverter({
              en: 'A Platform that connects Labs and Companies',
              kr: '연구실과 기업을 연결하는 공간'
            })
          }}
        </h2>
        <h1 class="wf__default-title" v-text="$route.meta.title"></h1>
      </div>
    </ImageBackgroundVue>

    <MenuNavigatorVue />
  </section>

  <div class="body-section">
    <router-view></router-view>
  </div>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.head-section {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  text-align: center;
}
.wf__subtitle {
  font-size: var(--wf-text-20);
}
.body-section {
  text-align: center;
}
</style>
